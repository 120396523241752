const initialState = {
    upArrow: false,
}

const TOGGLE_UP_ARROW = 'TOGGLE_UP_ARROW'


export const toggleUpArrow = upArrow => ({
    type: TOGGLE_UP_ARROW, upArrow
})


const App =  (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_UP_ARROW:
            return { ...state, upArrow: action.upArrow }
        default:
            return state
    }
}
export default App